<template>
    <v-app>
        <toast ref="toast"/>
        <template v-if="userSignedIn && !isLoading">
            <NavDrawer></NavDrawer>
            <NavBar></NavBar>
            <NotificationDrawer></NotificationDrawer>
        </template>
        <v-main>
            <router-view :key="$route.fullPath"></router-view>
        </v-main>
        <DialogsWrapper />
    </v-app>
</template>

<style lang="scss">
    @import './sass/fonts';

    #app, :root {
        @import './sass/components';
        @import './sass/classes';
    }
</style>

<script lang="ts">
import { Component, Vue, Watch, toNative } from 'vue-facing-decorator'
import { DialogsWrapper } from 'vuejs-confirm-dialog'
import NotificationDrawer from './components/NotificationDrawer.vue'
import Toast from './components/Toast.vue'
import WebsocketManager from './models/WebsocketManager'
import store from './stores'

@Component({
  components: { NotificationDrawer, Toast, DialogsWrapper }
})
class App extends Vue {
  declare public $refs: {
    toast: HTMLElement
  }

  $socket: any
  socketState = 0

  get redirectURL () {
    return store.state.redirectURL
  }

  get currentTenant () {
    return store.state.currentTenant
  }

  get userSignedIn () {
    return store.state.user && store.getters.userCustomData
  }

  get isLoading () {
    return store.state.loginLoading
  }

  created () {
    window.onbeforeunload = () => {
      this.$apolloProvider.defaultClient.stop()

      if (this.socketState === this.$socket.OPEN) {
        try {
          this.$socket.close()
        } catch {}
      }
    }

    if (this.redirectURL === null) {
      const stagingRegex = /\/staging\.v([a-z0-9.-])+/

      let pathName = window.location.pathname
      if (stagingRegex.test(window.location.href)) {
        if (pathName.endsWith('/staging.html')) {
          pathName = '/'
        } else {
          pathName = pathName.replace(stagingRegex, '')
        }
      }

      if (!pathName.endsWith('/signin')) {
        store.commit('setRedirectURL', pathName)
      }
    }
  }

  async mounted () {
    try {
      await store.dispatch('fetchCurrentUser')
      this.$router.push(this.redirectURL as string)
    } catch {
      this.$router.push({ name: 'Sign In' })
    }

    this.initializeWebsockets()
  }

  @Watch('currentTenant')
  onTenantChange (newTenant: any, oldTenant: any) {
    if ((newTenant?.id !== oldTenant?.id || (newTenant && !oldTenant)) &&
            this.socketState === this.$socket.OPEN
    ) {
      this.$socket.sendObj({ action: 'getConnectionId' })
    }
  }

  initializeWebsockets () {
    this.$options.sockets.onmessage = (message: any) => {
      let data
      try {
        data = JSON.parse(message.data)
      } catch {
        data = JSON.parse(atob(message.data))
      }

      new WebsocketManager(this.$refs.toast as any).handleMessage(data)
    }
    this.$options.sockets.onopen = () => {
      this.socketState = this.$socket.OPEN
      this.$socket.sendObj({ action: 'getConnectionId' })
      this.$socket.onclose = () => { this.socketState = this.$socket.CLOSED }
    }
    try {
      this.$socket.sendObj({ action: 'getConnectionId' })
    } catch {}
  }
}
export default toNative(App)
</script>
