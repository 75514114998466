import { allocationQueryName } from '@/models/AllocationMethod'
import Notification, { NotificationQueryData } from '@/models/Notification'
import RootState from '@/models/RootState'
import { evictCache } from '@/models/Utils'
import gql from 'graphql-tag'
import { ActionContext, Commit } from 'vuex'
import { apolloClient } from '../vue-apollo'

export default {
  namespaced: true,
  state () {
    return {
      notifications: [] as Notification[],
      totalCount: 0,
      page: 1,
      pageSize: 10 as number,
      loading: false,
      initialLoad: true
    } as NotificationQueryData
  },
  getters: {
    hasData: (state: NotificationQueryData) => state.initialLoad ||
            state.totalCount > state.notifications.length,
    unreadCount: (state: NotificationQueryData) => Math.max(
      state.notifications.filter((notification: Notification) => !notification.read).length + (state.totalCount - state.notifications.length),
      0
    ),
    unreadNotifications: (state: NotificationQueryData) => state.notifications.filter((notification: Notification) => !notification.read)
  },
  mutations: {
    pushNotification (state: NotificationQueryData, notification: Notification) {
      const notifications = [
        notification,
        ...state.notifications
      ]
      state.totalCount = state.totalCount + 1
      state.notifications = notifications
    },
    setNotifications (state: NotificationQueryData, notifications: Notification[]) {
      if (state.initialLoad) {
        state.initialLoad = false
        state.notifications = notifications
      } else {
        const updatedNotifications = [
          ...state.notifications,
          ...notifications
        ]
        state.notifications = updatedNotifications
      }
    },
    setNotificationsCount (state: NotificationQueryData, notificationsCount: number) {
      state.totalCount = notificationsCount
    },
    setNotificationsPage (state: NotificationQueryData, page: number) {
      state.page = page
    },
    setLoadingState (state: NotificationQueryData, value: boolean) {
      state.loading = value
    },
    resetStoreState (state: NotificationQueryData) {
      state.notifications = [] as Notification[]
      state.totalCount = 0
      state.page = 1
      state.pageSize = 10
      state.loading = false
      state.initialLoad = true
    },
    readNotifications (state: NotificationQueryData) {
      const notifications = [...state.notifications]
      for (const notification of notifications) {
        if (!notification.read) {
          notification.read = true
        }
      }
      state.notifications = notifications
    }
  },
  actions: {
    async markNotificationsRead (context: ActionContext<NotificationQueryData, RootState>) {
      if (context.getters.unreadCount > 0) {
        const res = await apolloClient.mutate({
          mutation: gql`mutation ($ids: [ID]!){
                        markNotificationsRead(ids: $ids) {
                            errors
                        }
                    }`,
          variables: {
            ids: context.getters.unreadNotifications.map((notification: Notification) => notification.id)
          }
        })

        if (res?.data?.markNotificationsRead && !(res.data.markNotificationsRead?.errors?.length > 0)) {
          context.commit('readNotifications')
        }
      }
    },
    async markAllNotificationsRead (context: ActionContext<NotificationQueryData, RootState>) {
      if (context.getters.unreadCount > 0) {
        const res = await apolloClient.mutate({
          mutation: gql`mutation {
                  markAllNotificationsRead {
                      errors
                  }
              }`
        })

        if (res?.data?.markAllNotificationsRead && !(res.data.markAllNotificationsRead?.errors?.length > 0)) {
          context.commit('readNotifications')
          context.commit('setNotificationsCount', 0)
        }
      }
    },
    pushNotification (context: ActionContext<NotificationQueryData, RootState>, notification: Notification) {
      if (notification?.parameters?.internalLink) {
        if (notification.parameters.internalLink.includes('jobs')) {
          evictCache(apolloClient, ['jobRuns', 'jobCount'])
          apolloClient.cache.gc()
        } else if (notification.parameters.internalLink.includes('reports')) {
          evictCache(apolloClient, ['reportRuns', 'reportRunCount'])
          apolloClient.cache.gc()
        } else if (
          notification.parameters.internalLink.includes('allocations') &&
          notification.parameters?.method
        ) {
          const queryName = allocationQueryName(notification.parameters.method)
          if (queryName) {
            evictCache(apolloClient, [queryName, `${queryName}Count`])
            evictCache(apolloClient, ['allocationSets'])
            apolloClient.cache.gc()
          }
        }
      }

      context.commit('pushNotification', notification)
    },
    resetStoreState ({ commit }: {commit: Commit}) {
      commit('resetStoreState')
    }
  }
}
